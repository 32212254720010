import * as React from "react";

import Page from "../components/Page";
import Container from "../components/Container";
import DefaultLayout from "../layouts/default";
import Wrapper from "../components/Wrapper";
import { breakpoints, colors, fonts } from "../styles/variables";
import styled from "styled-components";

const TitleSection = styled.div`
  margin-top: 50px;

  &.margin-needed {
    margin-top: 100px;
  }
`;
const MainTitle = styled.h1`
  font-size: 40px;
  color: ${colors.black};
  font-family: ${fonts.helvetica.regular};
  margin-top: 0;
`;
const ContentWrapper = styled.div`
  font-family: ${fonts.helvetica.regular};
  font-size: 18px;
  line-height: 1.4;
  display: block;

  @media screen and (min-width: ${breakpoints.lg}px) {
    display: flex;
    justify-content: space-between;
  }
`;
const MainContentWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    width: calc(55%);
  }
`;

const NotFoundPage = () => (
  <DefaultLayout>
    <Page>
      <Container>
        <Wrapper>
          <TitleSection>
            <MainTitle>Fehlende Angaben</MainTitle>
          </TitleSection>
          <ContentWrapper>
            <MainContentWrapper>
              <p>
                Bitte füllen Sie alle Felder aus.
                <br />
                <a
                  href="#"
                  onClick={() => {
                    typeof history !== "undefined" && history.go(-1);
                  }}
                >
                  Zurück zum Formular
                </a>
              </p>
            </MainContentWrapper>
          </ContentWrapper>
        </Wrapper>
      </Container>
    </Page>
  </DefaultLayout>
);

export default NotFoundPage;
